import React from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import Image from '../Image/Image'
import '@splidejs/react-splide/css'

const ContentImages = ({content, width}) => {
  // console.log('content.images', content.images)
  const isGallery = content.images?.length > 1 ? true : false

  return (
    <>
    {isGallery ? 
      <Splide 
        hasTrack={false}
        className={`relative z-0 h-full ${width} ${width === 'md:basis-full md:w-full' ? `px-4` : ``}`} 
        aria-label=''
        options={{
          rewind: true,
          arrows: true,
          pagination: true
        }}
      >
        <SplideTrack>
          {content.images?.map((el, i) => 
            <SplideSlide key={i}>
              <Image img={el} />
            </SplideSlide> 
          )}
        </SplideTrack>

        <div className="splide__arrows z-10 hideForMobile">
          <button className="splide__arrow splide__arrow--prev !left-8 !bg-transparent text-yellow !opacity-100 text-xl xl:text-3xl circle-activated">←</button>
          <button className="splide__arrow splide__arrow--next !right-8 !bg-transparent text-yellow !opacity-100 text-xl xl:text-3xl circle-activated">→</button>
        </div>
      </Splide>
      : 
      content.images?.map((el, i) => <Image key={i} img={el} width={width} /> )}
    </>
  )
}

export default ContentImages
