import React from 'react';
// import styles from './ContentTextImage.module.scss';
import ContentImages from '../ContentImages/ContentImages';
import { ConditionalWrapper } from '../helpers';

const ContentTextImage = ({content, width}) => {
  return(
    <div className={`relative ${width}`}>
      <div className='absolute w-full flex items-center inset-0 m-auto z-10 px-4'>
        <h2 className='block md:max-w-2xl xl:max-w-6xl m-auto text-yellow text-center text-4xl md:text-7xl xl:text-8xl 2xl:text-10xl leading-none uppercase'>
          <ConditionalWrapper
            condition={content.url}
            wrapper={children => <a href={content.url.href} className='circle-activated'>{children}</a>}
          >
            {content.text}
          </ConditionalWrapper>
        </h2>
      </div>

      {content.images ? <ContentImages content={content} /> : null}
    </div>
  )
}

export default ContentTextImage;
