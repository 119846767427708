import React from 'react'

import ContentPortableText from '../ContentPortableText/ContentPortableText';

const ContentText = ({content, width}) => {
  // console.log(content.content)
  return (
    <div className={`relative ${width !== 'md:basis-full md:w-full' ? `px-4` : `px-4 lg:px-0 before:inset-x-3 lg:before:inset-x-0`} ${content.rasterizedBorder?.rasterBefore === true ? `mt-16 md:mt-8 xl:mt-24 raster -before raster-black before:block before:absolute before:h-12 before:-top-16 xl:before:-top-20 before:inset-x-3 before:-z-10 rasterizedBorder` : ``}`}>
      <ContentPortableText content={content.content?.block} />
    </div>  
  )
}

export default ContentText;
