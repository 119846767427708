import React, { useRef } from 'react';
import styles from './ContentSpacer.module.scss';

const ContentSpacer = ({content}) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight])

  return(
    <div className={`relative block ${content.componentWidth?.width}`} style={{height: `${windowSize.current[0] >= 768 ? content.spacerHeight : content.spacerHeight/3}vh`}}></div>
  )
}

export default ContentSpacer;
