import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.scss';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from '@material-tailwind/react';
import { createBrowserRouter, createHashRouter, RouterProvider } from 'react-router-dom'
import Page from './components/Page/Page';

const theme = {
  accordion: {
    styles: {

    },
    defaultProps: {
      className: 'test'
    },
  }    
}

// createBrowserRouter
const router = createHashRouter([
  {
    path: "/",
    element: <Page isHome={'home'}/>,
  },
  {
    path: "/page/:slug",
    element: <Page />,
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider value={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
