import React from 'react';

const MenuButton = ({ menuOpen, navBarBg, toggleMenu }) => {
  // menuOpen={!menuOpen} text-base lg:text-xl leading-tight
  return (
    <p className='md:w-1/12 xl:w-2/12 flex justify-end flex-none text-yellow uppercase relative z-10 cursor-pointer' onClick={() => toggleMenu(!menuOpen)}>
      <span className={`${menuOpen ? `p-1` : `pl-4 xl:pl-8`} inline-block ${navBarBg ? 'bg-grey' : 'backdrop-blur-sm'}`}>{menuOpen ? 'Close' : 'Menu'}</span>
    </p>
  )
}

export default MenuButton;