import React, { useRef } from 'react';
import { urlFor } from '../helpers';
import { getImageDimensions } from '@sanity/asset-utils'

const Image = ({img, isInlineImg, width}) => {
  // console.log('img', img)
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const {imgWidth, imgHeight} = img ? getImageDimensions(img) : {}
  const ratio = img?.imgRatio?.ratio

  return (
    <figure className={`relative ${isInlineImg ? `mb-8` : ``} ${width === 'md:basis-full md:w-full' ? `px-4` : ``}`} >
      <img 
        src={urlFor()
          .image(img)
          .fit('max')
          .auto('format')
          .url().toString()
        }
        loading='lazy'
        alt={img.alt || ''}
        style={{ aspectRatio: ratio ? (ratio === (2/1) && windowSize.current[0] < 768 ? '3/2' : ratio) : imgWidth / imgHeight }}
        className={`relative object-cover w-full h-full z-0`}
      />

      {img.caption && <figcaption className='absolute h-auto w-auto bg-yellow top-4 md:top-auto md:bottom-4 left-7 z-10 px-4 py-1 rounded-full text-xs xl:text-sm font-extralight'>{img.caption}</figcaption>}
    </figure>
  )
}

export default Image