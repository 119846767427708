import React, { useState, useEffect, useRef } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useLocation, useParams } from 'react-router-dom'
import sanityClient from '../../Client'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import AnchorMenu from '../AnchorMenu/AnchorMenu'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import PageContent from '../PageContent/PageContent'

const Page = ({isHome}) => {

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  let location = useLocation()
  const ref = useRef(null)
  const refHeader = useRef(null)
  const {slug} = useParams()
  // const pageData = `*[_type in ["page", "siteConfig"]] {_type == "page" && (_id == "${pageID}" || slug.current == "${slug}") => {name, pageAnchorMenu, contentComponents}, _type == "siteConfig" => {frontpage} }`  
  // const pageData = `content[]{_type == "page" && (_id == "${pageID}" || slug.current == "${slug}") => {name, pageAnchorMenu, contentComponents} , _type == "siteConfig" => {frontpage}}`
  const pageData = isHome === 'home' ? `*[_type == "page" && slug.current == "${isHome}"] {_id, name, slug, description, pageAnchorMenu, contentComponents}` : `*[_type == "page" && (slug.current == "${slug}")] {name, slug, description, pageAnchorMenu, contentComponents}`
  const [currentPage, setCurrentPage] = useState(null)
  const [isScrolled, setIsScrolled] = useState(true)
  const [menuOpen, setMenuOpen] = useState(false)
  const [seoContent, setSeoContent] = useState(null)
  const [navBarBg, setNavBarBg] = useState(false)
  const [anchorMenu, setAnchorMenu] = useState(null)
  const [pageContent, setPageContent] = useState(null)

  useEffect(() => {
    console.log(location)
    document.body.style.overflow = 'auto'
    setMenuOpen(false)
    toggleNavBar(false)
    toggleScroll(true)
  }, [location])

  const scrollToAnchor = el => gsap.to(window, {duration: 1, scrollTo: el})

  const toggleNavBar = bg => setNavBarBg(bg)

  const toggleMenu = val => setMenuOpen(val)

  const toggleScroll = val => setIsScrolled(val)

  const pageScroll = (triggerWindow) => {
    ScrollTrigger.create({
      trigger: triggerWindow,
      start: 'top top',
      end: 'bottom bottom',
      onUpdate: self => {
        // console.log(self.scroll(), self.direction)
        // setNavBarBg(self.scroll() >= windowSize.current[1] ? true : false)
        setIsScrolled(self.direction === 1 && windowSize.current[0] >= 768 ? true : false)
      }
    })    
  }
  
  useEffect(() => {
    gsap.to(window, {duration: .5, scrollTo: 0 })
    const pageRef = ref.current
    const headerRef = refHeader.current
  
		sanityClient
      .fetch(pageData)
      .then((data) => {
        setCurrentPage(data[0]?.slug.current)
        setAnchorMenu(data[0]?.pageAnchorMenu)
        setPageContent(data[0]?.contentComponents)
        setSeoContent(data[0]?.description)
        pageScroll(pageRef, headerRef)
        // console.log(data[0])
      })
      .catch(console.error)
	}, [slug])

  useEffect(() => {
    if (!menuOpen) {
      gsap.to(refHeader.current, {
        y: !isScrolled ? '0%' : '-100%',
        duration: .5,
        ease: 'sine.out'
      })
    }
  }, [isScrolled])

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>8 MINUTES 2 STRUCTURE</title>
        <meta name="description" content={seoContent} />
      </Helmet>
      
      <div ref={ref} id={slug} className={`lg:container lg:mx-auto flex flex-wrap justify-center items-start relative w-full md:px-4`}>
        <header ref={refHeader} className={`fixed w-full inset-x-0 p-2 py-3 md:py-4 xl:py-6 px-4 md:px-6 z-50 ${navBarBg ? `bg-grey` : `bg-transparent`} -translate-y-full`}>
          <Header anchorMenu={<AnchorMenu/>} subMenu={anchorMenu} toggleMenu={toggleMenu} menuOpen={menuOpen} scrollToAnchor={scrollToAnchor} toggleNavBar={toggleNavBar} navBarBg={navBarBg} />
        </header>
      
        <PageContent content={pageContent} currentPage={currentPage} toggleNavBar={toggleNavBar} toggleScroll={toggleScroll} navBarBg={navBarBg} />
        
        <Footer/>
      </div>
    </HelmetProvider>
  )
}

export default Page