import React, { useEffect, useRef, useState } from 'react';
import { Motion, TransitionMotion, spring, presets } from 'react-motion';

const Slot = ({targetSymbol}) => {

  const alpha = Array.from(Array(26)).map((e, i) => i + 65)
  const numbers = Array.from(Array(10).keys())
  const Alphabet = alpha.map((x) => String.fromCharCode(x))
  const chars = [...Alphabet, ...numbers]
  const randChars = chars.map((a) => [Math.random(),a]).sort((a,b) => a[0]-b[0]).map((a) => a[1])

  const [randomChars, setRandomChars] = useState(randChars)
  const [startAnimation, setStartAnimation] = useState(false)
  const [targetOffsetTop, setTargetOffsetTop] = useState(null)
  const initialStyle = {top: 0}
  
  const slotRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      if (targetSymbol != null && slotRef.current != null) {
        const targetIndex = randomChars.indexOf(targetSymbol)
        const offsetTop = -slotRef.current.children[targetIndex].offsetTop
        setTargetOffsetTop(offsetTop)
      }
    }, 1000)
  },[]) 

  useEffect(() => {
    setStartAnimation(true) 
  }, [targetOffsetTop])
  
  // useEffect(() => {
  //   console.log('startAnimation', startAnimation)
  // }, [startAnimation])

  return (
    <div className="relative h-full mx-2 md:mx-4 overflow-hidden">
      <div className={`relative flex h-full overflow-hidden`}> 
        <Motion style={ targetOffsetTop !== null ? { top: spring(targetOffsetTop, presets.gentle) } : initialStyle } >
          {interpolatedStyles => (
            <div 
              ref={slotRef}
              className='relative w-auto'  //transition-[top] duration-[500ms] ease-in-out
              style={{top: `${interpolatedStyles.top}px`}} //
            >
              {randomChars.map((char, i) => <span key={i} className='block'>{char}</span>)}
            </div>
          )}
        </Motion>
      </div>
    </div>
  )
}
  
Slot.defaultProps = {};

export default Slot;
