import React, {useState, useEffect, useLayoutEffect, useRef} from 'react'
import useResizeObserver from '@react-hook/resize-observer'
import { gsap } from 'gsap'

import interimBgSmall from '../../images/Concept-Office-min_w_310.jpg'
import interimBgMedium from '../../images/Concept-Office-min_w_1381.jpg'
import interimBgLarge from '../../images/Concept-Office-min_w_2026.jpg'
import interimBgXLarge from '../../images/Concept-Office-min_w_2560.jpg'

import SlotMachine from '../SlotMachine/SlotMachine'

const useSize = (target) => {
  const [size, setSize] = useState()

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect())
  }, [target])

  useResizeObserver(target, (entry) => setSize(entry.contentRect))
  return size
}

function InterimLanding({toggleScroll}) {

  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const intro = useRef()
  const logo = useRef()
  const logoSize = useSize(logo)
  const introBgRaster = useRef()
  const introImg = useRef()

  const [introComplete, setIntroComplete] = useState(false)
  const [logoDimensions, setLogoDimensions] = useState({width: logo?.current?.clientWidth, height: logo?.current?.clientHeight})
  
  const completeIntro = val => setIntroComplete(val)
  const nameRasterSpace = windowSize.current[0] >= 768 ? 14 : 14
  
  useEffect(() => {
    console.log('windowsize ', windowSize.current)
    // console.log("updated", logo?.current?.clientWidth)
    document.body.style.overflow = 'hidden'
    setLogoDimensions({width: logo?.current?.clientWidth, height: logo?.current?.clientHeight})
  }, [logo?.current?.clientWidth, logo?.current?.clientHeight])

  useEffect(() => {
    if (introComplete === true) {
      gsap.to(introBgRaster.current, {
        opacity: 0,
        duration: .8,
        delay: 1,
        ease: 'none'
      })
      
      gsap.to([introImg.current], {
        filter: 'blur(0px)',
        scale: 1,
        duration: .8,
        delay: 1,
        ease: 'none',
        onComplete: () => {
          document.body.style.overflow = 'auto'
          toggleScroll(false)
        }
      })
    }
  }, [introComplete])

  return (
    <div ref={intro} className={`relative w-screen h-full overflow-hidden z-10 inset-y-0 inset-x-0 flex flex-col flex-wrap items-center align-items-center justify-center bg-grey`}>
      <img
          ref={introImg}
          src={interimBgXLarge}
          srcSet={`${interimBgSmall} 310w, ${interimBgMedium} 1381w, ${interimBgLarge} 2026w, ${interimBgXLarge} 2560w`}
          alt='8 Minutes 2 Structure Concept Office'
          className='absolute block top-0 left-0 bottom-0 right-0 w-screen -z-10 h-screen object-cover scale-[1.01]'
      />
      
      <div ref={introBgRaster} className='absolute dots dots-fill intro-bg' style={{
        clipPath: `polygon(
          0% 0%, 
          0% 100%,  
          ${(windowSize.current[0] - logoDimensions.width) / 2 - nameRasterSpace}px 100%, 
          ${(windowSize.current[0] - logoDimensions.width) / 2 - nameRasterSpace}px ${(windowSize.current[1] - logoDimensions.height) / 2 - nameRasterSpace}px,
          ${(windowSize.current[0] + logoDimensions.width) / 2 - nameRasterSpace}px ${(windowSize.current[1] - logoDimensions.height) / 2 - nameRasterSpace}px,
          ${(windowSize.current[0] + logoDimensions.width) / 2 - nameRasterSpace}px ${(windowSize.current[1] + logoDimensions.height) / 2 - nameRasterSpace}px,
          ${(windowSize.current[0] - logoDimensions.width) / 2 - nameRasterSpace}px ${(windowSize.current[1] + logoDimensions.height) / 2 - nameRasterSpace}px,
          ${(windowSize.current[0] - logoDimensions.width) / 2 - nameRasterSpace}px 100%,
          100% 100%,
          100% 0% 
        )`
      }}></div>

      <h1 ref={logo} id="logo" className='absolute h-[4.5rem] translate-y-[-32px] lg:translate-y-0 md:h-[8.25rem] lg:h-[5rem] xl:h-[6rem] 2xl:h-[8rem] flex flex-wrap justify-center self-center text-yellow text-center text-4xl md:text-7xl xl:text-8xl 2xl:text-10xl leading-none uppercase w-auto whitespace-normal lg:whitespace-nowrap z-20 overflow-hidden'> 
        <SlotMachine completeIntro={completeIntro}/>
      </h1>  
    </div>
  )
}

export default InterimLanding