import React from 'react';

const AnchorMenu = ({subMenu, navBarBg, scrollToAnchor}) => {

  // console.log('subMenu', subMenu?.length)
  // text-sm xl:text-base leading-4 xl:leading-tight

  return (
    <div className="relative w-9/12 xl:w-8/12 hidden lg:flex lg:flex-wrap lg:justify-center z-10">
      <div className="relative w-full px-2 flex items-center justify-between shrink-1 anchorLinksGrid">
        {subMenu?.map((item, i) => {
          return (
            <div key={i} className={`relative shrink-1 px-1 xl:px-3 text-yellow ${navBarBg ? 'bg-grey' : 'backdrop-blur-sm	'} cursor-pointer`} onClick={() => scrollToAnchor(`#${item.anchorID.anchor}`)} >
              <p className='px-2'><sup className='-top-1 mr-2'>↗</sup>{item.anchorText}</p>
            </div>
          )
        })}
      </div>
    </div>
  ) 
}

export default AnchorMenu;
