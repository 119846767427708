import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import sanityClient from '../../Client'

// import styles from './Footer.module.scss';

const Footer = () => {

  const today = new Date()
  const year = today.getFullYear()

  const [footerMenu, setFooterMenu] = useState(null)

  const footerMenuData = `*[_type == "siteConfig"] { footerMenuItems[] { _type == 'reference' => @-> { _type, _id, name, slug }, _type != 'reference' => @} }`
  
  useEffect(() => {
		sanityClient
      .fetch(footerMenuData)
      .then((data) => {
        setFooterMenu(data[0]?.footerMenuItems)
        // console.log(data)
      })
      .catch(console.error)
	}, [])

  return (
    <footer className="w-screen bg-grey text-yellow text-sm xl:text-base 2xl:text-lg !leading-6 uppercase">
      <div className="w-screen flex justify-between gap-x-4 py-2 md:py-6 px-4">
        <p className=''>
          <Link to="/" className="inline-block">8M2S</Link>
          <span className='md:hidden'>&copy; {year}</span>
        </p>

        <ul className='columns-2 md:gap-12 lg:flex lg:columns-1'>
          {footerMenu?.map((el, i) => {
            return (
              // text-yellow text-sm xl:text-base leading-none
              <li key={i} className='uppercase hover:bg-grey'>
                {el._type === 'page' ? 
                  <Link to={`/page/${el.slug.current}`} className="inline-block">{el.name}</Link>
                  : 
                  <a href={el.externalUrl} target="_blank" rel="noreferrer" className="inline-block"><sup className='-top-1 mr-2'>↗</sup>{el.linkText}</a>
                }
              </li>
            )
          })}
        </ul>

        <p className='hideForMobile'>&copy; {year}</p>
      </div>
    </footer>
  )
}
export default Footer;
