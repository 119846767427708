import React from 'react';
import { ConditionalWrapper } from '../helpers';
// import styles from './ContentTextRasterized.module.scss';

const ContentTextRasterized = ({content}) => {

  // console.log(content)

  return (
    // ${content.hasBg ? 'before:block before:absolute before:top-0 before:bottom-0 before:inset-x-0 before:-z-10 before:bg-grey before:rounded-lg' : ``}
    <div className={`relative flex flex-wrap h-full justify-center items-center px-4 py-20 md:py-24 lg:py-32 raster -before raster-${content.color} textRasterized ${content.hasBg ? 'rasterizedBg' : null}`}>

      <h2 className={`relative block text-${content.color} leading-none text-center text-4xl lg:text-6xl uppercase circle-activated`}>
        <ConditionalWrapper
          condition={content.url}
          wrapper={children => <a href={content.url.href}>{children}</a>}
        >
          {content.text}
        </ConditionalWrapper>
      </h2>

    </div>
  )
}

export default ContentTextRasterized;
