import React, { useEffect, useLayoutEffect, useRef } from 'react'

import ContentImages from '../ContentImages/ContentImages'
import ContentNumberedText from '../ContentNumberedText/ContentNumberedText'
import ContentSpacer from '../ContentSpacer/ContentSpacer'
import ContentTextRasterized from '../ContentTextRasterized/ContentTextRasterized'
import ContentTextImage from '../ContentTextImage/ContentTextImage'
import ContentText from '../ContentText/ContentText'
import ContentCollapsible from '../ContentCollapsible/ContentCollapsible'
import InterimLanding from '../InterimLanding/InterimLanding'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const PageContent = ({content, currentPage, toggleNavBar, toggleScroll, navBarBg }) => {

  // console.log('content', content, currentPage)
  gsap.registerPlugin(ScrollTrigger)

  const elRefs = useRef([])

  elRefs.current.forEach((el) => {
    ScrollTrigger.create({
      trigger: el,
      start: 'top bottom',
      end: 'bottom top+=10%',
      // markers: true,
      // onUpdate: self => {
      //   console.log(self.scroll(), elRef)
      // },
      onLeave: self => {
        if (el === elRefs.current[0]) {
          toggleNavBar(true)
        } 
        // self.scroll() >= windowSize.current[1] ? true : false
      }
    })
  })

  return (
    <>
      {/* {currentPage === 'home' ? <InterimLanding/> : null} ref={el => {elRefs.current.push(el)}} */ }
      {content?.map((content, i) => {
        const type = content._type
        const width = content.componentWidth?.width
        const align = content.componentWidth?.alignSelf ? content.componentWidth?.alignSelf : 'self-auto'
        const bg = content.hasBg ? 'bg-grey' : ''
        const rasterizedBorder = content.rasterizedBorder?.rasterBefore ? 'rasterizedBorder' : ''
        const isSpacer = content._type === 'contentSpacer' ? true : false
        const isMobileHidden = content.componentVisibility?.hideForMobile
        const isDesktopHidden = content.componentVisibility?.hideForDesktop

        return(
          <>
            {i === 0 && currentPage === 'home' ? <div key={'intro'} className='relative h-screen w-screen before:block before:absolute before:-bottom-12 before:h-12 before:bg-white before:w-full before:z-10'><InterimLanding toggleScroll={toggleScroll} /></div> : null}

            <div 
              ref={(el) => elRefs.current[i] = el} 
              key={i} id={content.anchorID ? content.anchorID?.anchor : content._key} 
              // className={`relative ${content._type !== 'contentSpacer' ? (i === 0 ? `mt-0 mb-4` : `my-8 lg:my-12`) : null} ${content._type === 'contentTextRasterized' && content.hasBg ? 'bg-grey' : null} ${content.componentWidth?.alignSelf ? content.componentWidth?.alignSelf : `self-auto`} w-full basis-full ${content.componentWidth?.width} shrink ${content._type} ${content.componentHideForMobile?.hideForMobile ? 'hideForMobile' : null}`}>
              className={`relative ${!isSpacer ? (i === 0 ? 'mt-0 mb-4' : 'my-8 lg:my-12') : ''} ${type === 'contentTextRasterized' && bg ? bg : ''} ${rasterizedBorder} ${align} w-full basis-full ${width} shrink ${type} ${isMobileHidden ? 'hideForMobile' : ''} ${isDesktopHidden ? 'hideForDesktop' : ''}`} >
                {
                  (() => {
                    switch (content._type) {
                      case 'contentText':
                        return <ContentText content={content} width={content.componentWidth?.width} />
                      
                      case 'contentImages':
                        return <ContentImages content={content} width={content.componentWidth?.width} />
                      
                      case 'contentNumberedText':
                        return <ContentNumberedText content={content} />
                    
                      case 'contentTextRasterized':
                        return <ContentTextRasterized content={content} />

                      case 'contentTextImage':
                        return <ContentTextImage content={content} width={content.componentWidth?.width} />
                      
                      case 'contentCollapsible':
                        return <ContentCollapsible content={content} />

                      case 'contentSpacer':
                        return <ContentSpacer content={content} />

                      default:
                        return null
                    }
                  }) ()
                }
            </div>
          </>
        )      
      })}
    </>
  )
}

export default PageContent