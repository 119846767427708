import React, { useState } from 'react';
import { Accordion, AccordionHeader, AccordionBody } from '@material-tailwind/react';
import ContentPortableText from '../ContentPortableText/ContentPortableText';
import Image from '../Image/Image';
import ContentImages from '../ContentImages/ContentImages';

function Icon({ id, open }) {
  return (
    <span className={`${id === open ? 'rotate-180' : null} block text-sm lg:text-base xl:text-lg 2xl:text-xl circle-activated -ml-6 origin-center transition-transform`}>↓</span>
  )
}

const ContentCollapsible = ({content}) => {
  // console.log(content)

  const collapsibleHeader = content.collapsibleContentHeader
  const collapsibleBody = content.collapsibleContentBody

  const [collapsibleOpen, setCollapsibleOpen] = useState(null)

  const handleCollapsibleOpen = (value) => setCollapsibleOpen(collapsibleOpen === value ? null : value)

  return (
    <div className='relative px-4 xl:px-6 mt-4 md:mt-16 lg:mt-24 raster -before raster-black before:block before:absolute before:h-12 before:-top-16 md:before:-top-20 before:inset-x-4 before:-z-10 rasterizedBorder'>
      <Accordion open={collapsibleOpen === content._key} icon={<Icon id={content._key} open={collapsibleOpen} className='font-ABCReproMono text-black-500'/>}>
        
        {collapsibleHeader.length === 1 &&
          <div className='relative mb-4 collapsibleHeader'>
            <ContentPortableText content={collapsibleHeader[0].content?.block} />

            {collapsibleHeader[0].collapsibleImage && <Image img={collapsibleHeader[0].collapsibleImage} width={'w-auto'}/>}
            
            <AccordionHeader onClick={() => handleCollapsibleOpen(content._key)} className={`${collapsibleOpen ? 'open' : null } font-ABCReproMono text-black-500 border-0 items-center flex-row-reverse justify-end py-2`}>
              <span className='self-center block ml-1 xl:ml-3 uppercase font-normal text-sm lg:text-base xl:text-lg 2xl:text-xl translate-y-1.5 md:translate-y-1.5'>{collapsibleOpen ? 'Close' : 'More'}</span>
            </AccordionHeader>
          </div>
        }
        
        <AccordionBody className='relative mb-4 py-4 collapsibleBody font-ABCReproMono text-black-500' id={content._key}>
          {collapsibleBody?.map((el, i) => el._type === 'content' ? <ContentPortableText key={i} content={el.block} /> : <ContentImages key={i} content={el} width={el.componentWidth?.width} /> )}
        </AccordionBody>

      </Accordion>      
    </div>
  )
}

export default ContentCollapsible;
