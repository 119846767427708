// import React from 'react'
import sanityClient from '../Client';
import imageUrlBuilder from '@sanity/image-url'

// Get a pre-configured url-builder from your sanity client
const builder = imageUrlBuilder(sanityClient)

// Then we like to make a simple function like this that gives the
// builder an image and returns the builder for you to specify additional parameters:
export function urlFor(source) {
  return builder.image(source)
}

export function imageRatio(value) {
  let ratio;
  switch (value) {
    case 'w-full':
      ratio = 2/1
      break
    
    case 'w-screen':
      ratio = 2/1
      break

    case 'w-auto':
      ratio = 'auto'
      break
    
    default:
      ratio = '16/9'
      break
  }

  return ratio
}

export const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;