import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import sanityClient from '../../Client'

// import styles from './MainMenu.module.scss';

const MainMenu = ({menuOpen}) => {

  const [primaryMenu, setPrimaryMenu] = useState(null)
  const [secondaryMenu, setSecondaryMenu] = useState(null)

  const mainMenuData = `*[_type == "siteConfig"] { primaryMenuItems[] -> { _id, name, slug }, secondaryMenuItems[] -> { _id, name, slug } }`  

  useEffect(() => {
		sanityClient
      .fetch(mainMenuData)
      .then((data) => {
        setPrimaryMenu(data[0]?.primaryMenuItems)
        setSecondaryMenu(data[0]?.secondaryMenuItems)
        // console.log(data)
      })
      .catch(console.error)
	}, [])

  return (
    <div className={`fixed inset-0 z-10 transition w-screen h-screen backdrop-blur-sm bg-grey ease-in-out delay-150 ${menuOpen ? `block` : `hidden`}`}>
      
      <div className='absolute top-0 left-0 h-full w-full z-10 raster -bg raster-yellow'></div>

      <div className='relative h-full z-20 flex flex-col justify-between items-center text-yellow text-4xl lg:text-5xl xl:text-7xl 2xl:text-8xl leading-none'>
        <h1 className='block w-full mt-12 md:mt-16 self-start text-center uppercase'>
          <Link to="/" className="inline-block px-2 py-1 md:px-2 bg-grey xl:bg-transparent">
            <span className='block md:hidden'>8M2S</span>
            <span className='hidden md:block'>8 Minutes 2 Structure</span>
          </Link>
        </h1>

        <div className='block my-8'>
          <ul className=''>
            {primaryMenu?.map((el, i) => {
              return (
                <li key={i} className='my-6 xl:my-8 text-center uppercase'>
                  <Link to={el.slug.current === 'home' ? `/` : `/page/${el.slug.current}`} className="inline-block px-2 pt-1 md:px-2 bg-grey xl:bg-transparent xl:hover:bg-grey">{el.name}</Link>
                </li>
              )
            })}
            <li className='my-6 lg:my-6 2xl:my-8 text-center uppercase'>
              <a href="mailto:info@8-minutes-to-structure.de" className='inline-block px-2 pt-1 md:px-2 bg-grey xl:bg-transparent xl:hover:bg-grey'>Kontakt</a>
            </li>
          </ul>
        </div>
        
        <div className='block mb-20 lg:mb-16 2xl:my-8'>
          <ul className='flex gap-x-4'>
            {secondaryMenu?.map((el, i) => {
              return (
                <li key={i} className='text-sm md:text-base lg:text-lg my-4 md:my-0 text-center uppercase'>
                  <Link to={`/page/${el.slug.current}`} className="inline-block px-2 pt-1 lg:p-2 bg-grey xl:bg-transparent xl:hover:bg-grey">{el.name}</Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>

    </div>
  )
}

MainMenu.propTypes = {}

export default MainMenu;