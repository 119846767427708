import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import MainMenu from '../MainMenu/MainMenu';
import MenuButton from '../MenuButton/MenuButton';
import AnchorMenu from '../AnchorMenu/AnchorMenu';

const Header = ({children, menuOpen, subMenu, navBarBg, toggleMenu, scrollToAnchor}) => {

  let location = useLocation()

  return (    
    <nav className='relative w-full flex items-center justify-between text-yellow text-lg leading-tight raster raster-yellow'>
      <h1 className='relative md:w-1/12 xl:w-2/12 flex-none uppercase z-10 logo'>
        {location.pathname === '/' ? 
          <span className={`inline-block pr-4 xl:pr-8 ${navBarBg ? 'bg-grey' : 'backdrop-blur-sm'}`} onClick={() => scrollToAnchor(`#root`)} >8M2S</span>
          :
          <Link to="/" className={`inline-block pr-4 xl:pr-8 ${navBarBg ? 'bg-grey' : 'backdrop-blur-sm'}`}>8M2S</Link>
        }
      </h1>

      <AnchorMenu subMenu={subMenu} navBarBg={navBarBg} scrollToAnchor={scrollToAnchor} />

      <MainMenu menuOpen={menuOpen} />
      
      <MenuButton menuOpen={menuOpen} toggleMenu={toggleMenu} navBarBg={navBarBg} />
    </nav>
  )
}

export default Header;
