import React from 'react'
import getYouTubeId from 'get-youtube-id'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { Link } from 'react-router-dom'

import { PortableText } from '@portabletext/react'
import Image from '../Image/Image'
// import CalltoAction from '../CalltoAction/CalltoAction'

const serializers = {
  types: {
    // youtube: ({node}) => {
    //   console.log(node)
    //   const { url } = node
    //   const id = getYouTubeId(url)
    //   return id
    // }
  }
}

const portableTextComponents = {
  types: {
    image: ({value}) => <Image img={value} isInlineImg={true} />,
    // youtube: ({value}) =><LiteYouTubeEmbed id={value.videoUrl} /> //<div>{value.videoUrl}</div>
  },

  marks: {
    centered: ({children}) => <span className='inline-block text-center'>{children}</span>,
    rounded: ({children}) => <span className='circle-activated -translate-x-2'>{children}</span>,
    underline: ({children}) => <span className='underline underline-offset-4 decoration-solid decoration-current decoration-2'>{children}</span>,
    link: ({children, value}) => {
      const target = value?.href.startsWith('http') ? '_blank' : undefined
      // console.log(children, value)
      if (target === undefined) {
        return value?.href.includes('mailto') ? <a href={value?.href }>{children}</a> : <Link to={`/page/${value?.href}`}>{children}</Link> 
      } else {
        return <a href={value?.href} target={target} rel={target === '_blank' && 'noindex nofollow'} className='underline underline-offset-4'>{children}</a>
      }
    }
    // strong: ({children}) => <strong className='font-bold'>{children}</strong>,
  },

  block: {
    normal: ({children}) => <p className="text-sm lg:text-base xl:text-lg 2xl:text-xl leading-tight mb-4 xl:mb-6">{children}</p>,
    large: ({children}) => <p className="text-xl lg:text-2xl xl:text-3xl leading-tight font-extralight">{children}</p>,
    small: ({children}) => <small className="text-xs lg:text-sm leading-3 md:leading-tight font-extralight my-2">{children}</small>,
    h1: ({children}) => <h1 className="text-2xl md:text-4xl xl:text-8xl 2xl:text-10xl leading-tight uppercase my-2 md:my-4">{children}</h1>,
    h2: ({children}) => <h2 className="text-base lg:text-2xl xl:text-4xl leading-tight uppercase my-2 md:my-4">{children}</h2>,
    h3: ({children}) => <h3 className="text-base lg:text-xl xl:text-3xl leading-snug uppercase my-2 md:my-4">{children}</h3>,
    h4: ({children}) => <h4 className="text-base lg:text-lg xl:text-2xl leading-snug uppercase my-2 md:my-4">{children}</h4>,
    h5: ({children}) => <h5 className="text-base lg:text-xl xl:text-xl leading-tight uppercase my-2 md:my-4">{children}</h5>,
    // button: ({children}) => <CalltoAction>{children}</CalltoAction>, 
  },

  list: {
    bullet: ({children}) => <ul className='list-arrow list-inside mb-4 xl:mb-6'>{children}</ul>,
    number: ({children}) => <ol className='list-number list-inside mb-4 xl:mb-6'>{children}</ol>,
  },

  listItem: {
    // bullet: ({children}) => <li className='text-sm md:text-base xl:text-xl leading-snug'>{children}</li>,
    bullet: ({children}) => <li className='relative mb-1 xl:mb-3 before:content-["\2192\a0"] before:-inset-y-1 lg:before:inset-y-0 text-sm md:text-base xl:text-xl leading-tight'>{children}</li>,
  },
}

const ContentPortableText = ({content}) => {
  return(
    <PortableText 
      value={content} 
      components={portableTextComponents}
      types={serializers}
    />
  )
}

export default ContentPortableText;
