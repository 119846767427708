import React from 'react';
import ContentPortableText from '../ContentPortableText/ContentPortableText';

const ContentNumberedText = ({content}) => {
  return (
    <ul className='relative -my-2 pl-4 list-decimal text-base md:text-2xl'>
      {content.numberedText?.map((el, i) => <li key={i} className='relative mb-12 pl-12 md:pl-16 before:-inset-y-1 lg:before:inset-y-0'><ContentPortableText content={el.block} /></li>)}
    </ul>
  )
}

export default ContentNumberedText;
