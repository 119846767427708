import React, {useState, useEffect, useRef} from 'react'
import { gsap } from 'gsap';
import Slot from '../Slot/Slot'

function SlotMachine({completeIntro}) {

  const intro = useRef()
  const tl = useRef()

  const [isLogo, setIsLogo] = useState(false)

  const roll = () => setTimeout(() => setIsLogo(true), 1250)

  useEffect(() => {
    setTimeout(() => roll(), 750)
  }, [])

  // useEffect(() => {
  //   console.log("updated", intro?.current?.clientWidth)
  // }, [intro?.current?.clientWidth]);
  
  useEffect(() => {
    if (isLogo) {
      const ctx = gsap.context(() => {
        tl.current && tl.current.progress(0).kill()
        tl.current = gsap.timeline({
          paused: true,
          onComplete: () => completeIntro(true)
        })
        .to('.minutes', {
          width: 'auto',
          duration: .5,
          delay: .5
        })
        .to('.structure', {
          width: 'auto',
          duration: .5,
        })
      }, intro)
      tl.current.play()
      return () => ctx.kill()
    }
  }, [isLogo])
  
  return (
    <div className='relative h-full lg:flex lg:flex-nowrap justify-center gap-x-2' ref={intro}>
      <div className='relative w-auto h-1/2 lg:h-full overflow-hidden flex justify-center'>
        <Slot targetSymbol={8} />
        
        <div className='relative flex h-full mx-2 md:mx-4 w-auto overflow-hidden'>
          <span className='block'>M</span>
          <span className='block w-0 overflow-hidden minutes'>inutes</span>
        </div>
      </div>
        
      <div className='relative w-auto h-1/2 lg:h-full flex justify-center'>
        <Slot targetSymbol={2} />

        <div className='relative flex h-full mx-2 md:mx-4 w-auto overflow-hidden'>
          <span className='block'>S</span>
          <span className='block w-0 overflow-hidden structure'>tructure</span>
        </div>
      </div>
    </div>
  )
}

export default SlotMachine